import React, { FunctionComponent, useState } from 'react';
import { RANGE_FACET_VALUE_SEPARATOR } from '../../../constants/search';
import { TrackedEvent } from '../../../helpers/analytics/event-types';
import { trackSearchEvent } from '../../../helpers/search-helper/search-analytics.helper';
import { getFacetDisplayValue, getFacetGroupDisplayName, isRangeFacetGroup } from '../../../helpers/search-helper/search-helper';
import { useSearchResults, UseSearchResultsPayload } from '../../../hooks/apollo/search/search.hooks';
import { FacetWithSource, SelectedFacet, SelectedFacetGroup } from '../../../types/search.types';
import { TextButton } from '../../buttons';
import { Popover } from '../../common-components/popover/popover.component';
import { CloseIcon, HelpCircleIcon } from '../../svg/icons.component';

const AutoAppliedFacetHelp: FunctionComponent = () => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<Popover
			popOverClassName="w5 f6"
			isVisible={isOpen}
			setIsVisible={setIsOpen}
			toggleElement={
				<div className="flex items-center pa1 pointer">
					<HelpCircleIcon className="theme-secondary" />
				</div>
			}>
			This filter has been automatically applied based on your search terms.
		</Popover>
	);
};

const FacetFilterPill: FunctionComponent<{
	facet: SelectedFacet;
	group: SelectedFacetGroup;
	query: string;
	removeFacet: (facet: FacetWithSource) => void;
	createGTMEvent: UseSearchResultsPayload['createGTMEvent'];
	isPlaList?: boolean;
}> = ({ facet, group, query, removeFacet, createGTMEvent, isPlaList }) => {
	const facetDisplayValue = getFacetDisplayValue(facet, group);

	const removeClick = () => {
		removeFacet({ id: facet.facetId, value: facet.value, sourceFacetId: facet.sourceFacetId });
		void trackSearchEvent(createGTMEvent(TrackedEvent.FACET_INTERACTION, { query, facet, group, applied: false }));
	};

	return (
		<div key={facet.facetId} className="dib">
			<div
				className={`ba ${
					isPlaList ? 'pa2 f6 br-pill b--theme-black mb3' : 'pa1 b--theme-tertiary mt2'
				} mr3 items-center inline-flex`}>
				{isPlaList && <span className="fw7 mh2">{getFacetGroupDisplayName(group)}:</span>}
				<span className="pa1">{facetDisplayValue}</span>
				<div className={`${!isPlaList ? 'ml3 bl b--theme-grey-lighter' : ''}`}>
					{facet.autoApplied ? (
						<AutoAppliedFacetHelp />
					) : (
						<TextButton
							onClick={removeClick}
							color={isPlaList ? 'black' : 'secondary'}
							ariaLabel={`Remove filter: ${group.name}: ${facetDisplayValue}`}>
							<div className="flex items-center pa1">
								<CloseIcon />
							</div>
						</TextButton>
					)}
				</div>
			</div>
		</div>
	);
};

export type FacetFilterListProps = { useResults?: () => UseSearchResultsPayload };
export const FacetFilterList: FunctionComponent<FacetFilterListProps> = ({ useResults = useSearchResults }) => {
	const { query, results, previousResults, clearFacets, removeFacet, loading, isNewLookAndFeel, createGTMEvent } = useResults();
	const selectedFacetGroups = (loading ? previousResults : results)?.selectedFacetGroups ?? [];
	const hasRemovableFacets = selectedFacetGroups.some((g) => g.facets.some((f) => !f.autoApplied));
	const clearAllFacets = () => {
		selectedFacetGroups.forEach((group) => {
			group.facets.forEach((facet) => {
				if (!facet.autoApplied) {
					void trackSearchEvent(createGTMEvent(TrackedEvent.FACET_INTERACTION, { query, facet, group, applied: false }));
				}
			});
		});
		clearFacets();
	};

	return selectedFacetGroups.length ? (
		<div>
			{hasRemovableFacets && !isNewLookAndFeel && (
				<TextButton automationHook="clear-all-facets" onClick={clearAllFacets}>
					x Clear All
				</TextButton>
			)}
			{selectedFacetGroups.map((group) => {
				const isRangeGroup = isRangeFacetGroup(group);
				let rangeFacet, rangeMin, rangeMax;

				if (isRangeGroup) {
					rangeMin = group.range?.min;
					rangeMax = group.range?.max;
					rangeFacet = {
						facetId: group.facets[0].facetId,
						value: `${rangeMin}${RANGE_FACET_VALUE_SEPARATOR}${rangeMax}`,
						autoApplied: group.facets[0].autoApplied
					};
				}

				return (
					<div key={group.id} className="dib">
						{!isNewLookAndFeel && <span className="fw7 mh2">{getFacetGroupDisplayName(group)}:</span>}
						{isRangeGroup ? (
							<FacetFilterPill
								key={`${group.id}:${rangeMin}-${rangeMax}`}
								facet={rangeFacet}
								group={group}
								query={query}
								removeFacet={removeFacet}
								isPlaList={isNewLookAndFeel}
								createGTMEvent={createGTMEvent}
							/>
						) : (
							group.facets.map((facet) => (
								<FacetFilterPill
									key={`${facet.facetId}:${facet.value}`}
									facet={facet}
									group={group}
									query={query}
									removeFacet={removeFacet}
									isPlaList={isNewLookAndFeel}
									createGTMEvent={createGTMEvent}
								/>
							))
						)}
					</div>
				);
			})}
			{hasRemovableFacets && isNewLookAndFeel && (
				<TextButton automationHook="clear-all-facets" onClick={clearAllFacets}>
					Clear Filters
				</TextButton>
			)}
		</div>
	) : null;
};
