import React, { FunctionComponent, useEffect, useState } from 'react';
import { FEATURE_FLAGS } from '../../../constants/general';
import { FACET_GROUP_FILTER_SHOW_SIZE, RANGE_FACET_VALUE_SEPARATOR } from '../../../constants/search';
import { TrackedEvent } from '../../../helpers/analytics/event-types';
import { formatNumber, generateDataSelector } from '../../../helpers/general-helper/general-helper';
import { handleKeys } from '../../../helpers/keyboard/keyboard.helper';
import { trackSearchEvent } from '../../../helpers/search-helper/search-analytics.helper';
import {
	facetGroupIsQuickShip,
	findSelectedFacet,
	getFacetDisplayValue,
	getFacetGroupDisplayName,
	getRangeFacetValues,
	getUnitAffixes,
	isRangeFacetGroup
} from '../../../helpers/search-helper/search-helper';
import {
	UseFacetGroupResults,
	useFacetGroupResults,
	useSearchResults,
	UseSearchResultsPayload
} from '../../../hooks/apollo/search/search.hooks';
import { useFeature } from '../../../hooks/features/features.hooks';
import { FacetGroup as FacetGroupType, FacetResult, FacetValueBase, SelectedFacet } from '../../../types/search.types';
import { TextButton } from '../../buttons';
import { PanelComponent } from '../../common-components/panel-component/panel-component.component';
import { Popover } from '../../common-components/popover/popover.component';
import { TextInput } from '../../inputs';
import { Checkbox } from '../../inputs/checkbox/checkbox.component';
import { quickShipIcon } from '../../product-components/shipping-eligibility/shipping-eligibility.css';
import { ChevronDownIcon, ChevronRightIcon, ChevronUpIcon, HelpCircleIcon, QuickShipIcon } from '../../svg/icons.component';
import { Range } from '../range/range.component';
import { SortByDropdown } from '../sort-by-dropdown/sort-by-dropdown.component';
import {
	facetListBackground,
	facetListGroup,
	facetListValues,
	facetSubHeading,
	facetTooltip,
	greyFacetSubHeading,
	plaFacetListValues
} from './facet-list.css';

const QuickShipLabel: FunctionComponent = () => (
	<div className={'dib v-top theme-quickship b'}>
		<QuickShipIcon className={`f2 mr1 relative ${quickShipIcon}`} />
		<span>Free 1- or 2- Day Shipping</span>
	</div>
);

type SelectedFacetsSubHeadingProps = {
	group: FacetGroupType;
	facets: SelectedFacet[];
	isPlaList?: boolean;
	capitalizeValues?: boolean;
};

const SelectedFacetsSubHeading: FunctionComponent<SelectedFacetsSubHeadingProps> = ({
	group,
	facets,
	isPlaList = false,
	capitalizeValues = false
}) => {
	if (!facets.length) {
		return null;
	}
	const isQuickShip = facetGroupIsQuickShip(group);
	const isRangeGroup = isRangeFacetGroup(group);
	const { selectedMinimum, selectedMaximum, isSelected } = getRangeFacetValues(group, facets);
	if (isRangeGroup && !isSelected) {
		return null;
	}

	const rangeFacet = isRangeGroup
		? {
				facetId: facets[0].facetId,
				value: `${selectedMinimum}${RANGE_FACET_VALUE_SEPARATOR}${selectedMaximum}`
		  }
		: null;
	const capitalizeClass = capitalizeValues && !rangeFacet ? 'ttc' : '';

	return (
		<div className="ph2 mt1 fw3 w-100" data-testid="selectedFacetSubHeading">
			{(!isRangeGroup || isPlaList) && (
				<div className={`f7 lh-copy lh-title-l ${isPlaList ? greyFacetSubHeading : facetSubHeading}`}>
					{!isRangeGroup && facets.length} Selected
				</div>
			)}
			{!isQuickShip && !isPlaList && (
				<div className={`f6 fw4 theme-primary ${capitalizeClass}`}>
					{rangeFacet
						? getFacetDisplayValue(rangeFacet, group)
						: facets.map((facet) => getFacetDisplayValue(facet, group)).join(', ')}
				</div>
			)}
		</div>
	);
};

export type FacetsFilterProps = {
	group: FacetGroupType;
	value: string;
	onChange: (newValue: string) => void;
	isPlaList?: boolean;
};

const FacetsFilter: FunctionComponent<FacetsFilterProps> = ({ group, value, onChange, isPlaList = false }) => {
	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value);
	return (
		<li className="flex items-center hover-bg-theme-grey-lighter pa2">
			<TextInput
				value={value}
				onChange={onInputChange}
				placeholder={`Search by ${group.name}`}
				ariaLabel={`Filter ${group.name} list`}
				className={`f6-l ${isPlaList ? 'pv2' : ''}`}
				automationHook={`${group.name}`}
			/>
		</li>
	);
};

export type FacetProps = {
	onFacetChange: (facet: any) => void;
	group: FacetGroupType;
	selectedFacets?: SelectedFacet[];
	facet: FacetResult;
	isPlaList: boolean;
	index: number;
	showFilter: boolean;
};

const Facet: FunctionComponent<FacetProps> = ({ onFacetChange, group, selectedFacets, facet, isPlaList, index, showFilter }) => {
	const isRangeGroup = isRangeFacetGroup(group);
	if (isRangeGroup) {
		const onRangeFacetChange = (lowRange: string, highRange: string) => {
			// Combine high and low values into one string using RANGE_FACET_VALUE_SEPARATOR for Search Hook methods
			const rangeValue = lowRange && highRange ? `${lowRange}${RANGE_FACET_VALUE_SEPARATOR}${highRange}` : '';
			onFacetChange({ facetId: facet.facetId, value: rangeValue });
		};
		const rangeValues = getRangeFacetValues(group, selectedFacets);
		const { unitPrefix, unitSuffix } = getUnitAffixes(group);
		return (
			<Range
				min={rangeValues.rangeMinimum}
				max={rangeValues.rangeMaximum}
				selectedMin={rangeValues.selectedMinimum}
				selectedMax={rangeValues.selectedMaximum}
				groupName={group.name}
				isSelected={rangeValues.isSelected}
				unitPrefix={unitPrefix}
				unitSuffix={unitSuffix}
				onRangeSubmit={onRangeFacetChange}
			/>
		);
	}

	const isQuickShip = facetGroupIsQuickShip(group);
	const label = isQuickShip ? <QuickShipLabel /> : getFacetDisplayValue(facet, group);
	const selectedFacet = selectedFacets?.find(
		(item) => item.facetId === facet.facetId && item.value.toLowerCase() === facet.value.toLowerCase()
	);
	const isSelected = Boolean(selectedFacet);
	const isAutoApplied = Boolean(selectedFacet?.autoApplied);
	const onChange = (e: React.SyntheticEvent) => {
		e.preventDefault();
		onFacetChange(facet);
	};

	return (
		<li className="pointer">
			<div
				role="button"
				tabIndex={0}
				aria-disabled={isAutoApplied}
				className={`flex items-center overflow-hidden hover-bg-theme-grey-lighter ${
					isPlaList ? 'pl2-l pv2' : `pa2 ${index !== 0 || showFilter ? 'bn bt-l b--theme-tertiary' : ''}`
				}`}
				onClick={onChange}
				onKeyPress={handleKeys(['Enter', ' '], onChange)}
				data-testid="searchFacet">
				<div className={'ml3 ml1-l f2 f6-l fw4'}>
					<Checkbox
						id={`${facet.value}-${facet.facetId}`}
						label={
							<div className="flex items-end content-center">
								<span className={`f5 f6-l ml2 ml0-l ws-normal ${isAutoApplied ? 'theme-grey' : ''}`}>{label}</span>
								<span className={`f5 f6-l ml2 theme-grey lh-solid`}>({formatNumber(facet.count)})</span>
							</div>
						}
						name={facet.value}
						value={facet.value}
						isChecked={isSelected}
						automationHook={facet.value}
						tabIndex={-1}
						readOnly={true}
						disabled={isAutoApplied}
					/>
				</div>
			</div>
		</li>
	);
};

export type FacetsProps = {
	group: FacetGroupType;
	selectedFacets?: SelectedFacet[];
	onFacetChange: (facet: FacetValueBase) => void;
	isPlaList?: boolean;
};

const Facets: FunctionComponent<FacetsProps> = ({ group, selectedFacets, onFacetChange, isPlaList = false }) => {
	const [filterValue, setFilterValue] = useState('');
	const showFilter = group.facets.length > FACET_GROUP_FILTER_SHOW_SIZE;
	let facets = group.facets,
		onFilterChange;
	if (showFilter) {
		onFilterChange = (newValue: string) => setFilterValue(newValue);
		const cleanedFilterValue = filterValue.trim().toLowerCase();
		if (cleanedFilterValue) {
			facets = facets.filter((facet) => facet.value.toLowerCase().includes(cleanedFilterValue));
		}
	}

	const isRangeGroup = isRangeFacetGroup(group);
	const listItemClasses = !isPlaList ? `pt0-l bt b--theme-tertiary ${!isRangeGroup && facetListValues}` : '';

	const showScrollbar = group.facets.length >= 8 || (group.facets.length >= 6 && showFilter);
	const plaFacetListClasses = showScrollbar ? `overflow-scroll pb4 ${plaFacetListValues}` : '';

	return (
		<li>
			<ul className={`pt2 pl0 bg-theme-white ma0 list ${listItemClasses}`} data-automation={`facet-range`}>
				<div className={isPlaList ? plaFacetListClasses : ''}>
					{showFilter && <FacetsFilter isPlaList={isPlaList} group={group} value={filterValue} onChange={onFilterChange} />}
					{facets.map((facet, index) => (
						<Facet
							key={`${facet.facetId}-${facet.value}`}
							facet={facet}
							group={group}
							index={index}
							isPlaList={isPlaList}
							onFacetChange={onFacetChange}
							showFilter={showFilter}
							selectedFacets={selectedFacets}
						/>
					))}
					{facets.length === 0 && (
						<div className={'flex items-center pa2 overflow-hidden'}>
							<div className="f5 f6-l ml2 ml0-l">No {filterValue ? 'filtered' : ''} values found.</div>
						</div>
					)}
				</div>
			</ul>
		</li>
	);
};

export type FacetGroupInfoProps = {
	description: string;
	term: string;
	isPlaList?: boolean;
	isNewLookAndFeel?: boolean;
};

const FacetGroupInfo: FunctionComponent<FacetGroupInfoProps> = ({ description, term, isPlaList = false, isNewLookAndFeel = false }) => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<div className={`dn db-ns pl2-m pr2-l mt0-l ${isNewLookAndFeel ? 'pt3-ns pt2' : 'pt1-ns'}`}>
			<Popover
				isVisible={isOpen}
				setIsVisible={setIsOpen}
				direction={'bottom'}
				toggleElement={<HelpCircleIcon className={`pointer ${!isPlaList && 'theme-secondary'}`} />}>
				<PanelComponent
					headingContent={`Get help with ${term}`}
					containerClassName={`${facetTooltip} pa3`}
					className={'f7'}
					headerClassName={'f6 bg-theme-grey-lighter'}>
					<div style={{ minWidth: '20rem' }} dangerouslySetInnerHTML={{ __html: description }}></div>
				</PanelComponent>
			</Popover>
		</div>
	);
};

export type FacetGroupProps = {
	group: FacetGroupType;
	selectedFacets?: SelectedFacet[];
	isExpanded: boolean;
	onClick: (group: FacetGroupType) => void;
	useResults: () => UseSearchResultsPayload;
	useSearchFacetGroupResults: (facetGroupId: string) => UseFacetGroupResults;
};

const FacetGroup: FunctionComponent<FacetGroupProps> = ({
	group,
	selectedFacets = [],
	isExpanded,
	onClick,
	useResults,
	useSearchFacetGroupResults
}) => {
	const { query, addFacet, removeFacet, categoryId, isNewLookAndFeel, createGTMEvent, results } = useResults();
	const { loadFacetGroup, facetGroup: fullFacetGroup, called: loadFacetGroupCalled } = useSearchFacetGroupResults(group.id);
	const isSolr = results?.searchEngine === 'SOLR';

	// Fetch full facet group if expanded and more available.
	if (isExpanded && group.metadata.hasMoreFacets && !loadFacetGroupCalled) {
		loadFacetGroup();
	} else if (fullFacetGroup) {
		group = { ...fullFacetGroup, range: group.range, info: group.info, unitPrefix: group.unitPrefix, unitSuffix: group.unitSuffix };
	}

	const isQuickShip = facetGroupIsQuickShip(group);
	const isRangeGroup = isRangeFacetGroup(group);
	const facetGroupName = getFacetGroupDisplayName(group);

	const handleFacetChange = (facet: FacetValueBase) => {
		const selectedFacet = findSelectedFacet(selectedFacets, facet, { isQuickShip, isRangeGroup });
		const pageQuery = categoryId ? `c${categoryId}` : query;
		if (selectedFacet?.autoApplied) {
			return;
		}

		// Range facet behaves differently. Don't try to remove if selected.
		if (!facet.value || (selectedFacet && !isRangeGroup)) {
			removeFacet({ id: facet.facetId, value: facet.value, sourceFacetId: selectedFacet?.sourceFacetId });
		} else {
			addFacet({ id: facet.facetId, value: facet.value });
		}

		void trackSearchEvent(
			createGTMEvent(TrackedEvent.FACET_INTERACTION, {
				query: pageQuery,
				facet,
				group,
				applied: !selectedFacet
			})
		);
	};

	const handleFacetGroupPress = (e: React.SyntheticEvent) => {
		e.preventDefault();
		onClick(group);
	};

	const chevronStyle = `fw2 ${!isNewLookAndFeel && 'theme-grey'} mt1`;
	const listItemClassName = !isNewLookAndFeel
		? `mt2 mt0-l bb b--theme-tertiary br0 pv2 pa0-l items-center ${facetListGroup}`
		: 'mt0 pt4 pb0 ph2 items-start';

	return (
		<div>
			<li className={`${listItemClassName} flex justify-between-l`} data-automation={generateDataSelector('facet', facetGroupName)}>
				<div className={`w-90-ns w-100 flex items-start pointer ${!isNewLookAndFeel ? 'pa3 pa2-l' : 'pb0'}`}>
					<div
						className="flex w-100 justify-start items-start justify-end-l flex-row-reverse-l"
						role="button"
						tabIndex={0}
						onClick={handleFacetGroupPress}
						onKeyDown={handleKeys(['Enter', ' '], handleFacetGroupPress)}
						data-testid="searchFacetGroup">
						<div className="w-100 self-end">
							<h2 className={`w-80 mv0 ml2 lh-title f5 truncate ${isNewLookAndFeel ? 'fw7' : 'fw4 fw3-l'}`}>
								{facetGroupName}
							</h2>
							<SelectedFacetsSubHeading
								isPlaList={isNewLookAndFeel}
								group={group}
								facets={selectedFacets}
								capitalizeValues={isSolr}
							/>
						</div>
						{isExpanded ? (
							<div className={isNewLookAndFeel ? 'f4' : ''}>
								<ChevronDownIcon className={`${chevronStyle} dn db-l`} />
								<ChevronUpIcon className={`${chevronStyle} db dn-l`} />
							</div>
						) : (
							<div className={isNewLookAndFeel ? 'f4' : ''}>
								<ChevronRightIcon className={`${chevronStyle} dn db-l`} />
								<ChevronDownIcon className={`${chevronStyle} db dn-l`} />
							</div>
						)}
					</div>
				</div>
				{group.info && <FacetGroupInfo isPlaList={isNewLookAndFeel} description={group.info.description} term={group.info.term} />}
			</li>
			{isExpanded && (
				<Facets isPlaList={isNewLookAndFeel} group={group} selectedFacets={selectedFacets} onFacetChange={handleFacetChange} />
			)}
		</div>
	);
};

export type FacetListProps = {
	listContainerStyle?: React.CSSProperties;
	hideDesktopHeader?: boolean;
	scrollable?: boolean;
	includeSortBy?: boolean;
	useResults?: () => UseSearchResultsPayload;
	useSearchFacetGroupResults?: (facetGroupId: string) => UseFacetGroupResults;
};

export const FacetList: FunctionComponent<FacetListProps> = ({
	listContainerStyle,
	hideDesktopHeader = false,
	scrollable = false,
	includeSortBy = false,
	useResults = useSearchResults,
	useSearchFacetGroupResults = useFacetGroupResults
}) => {
	const [savedExpandedFacetGroups, setExpandedFacetGroups] = useState<{ id: string }[]>();
	const { results, previousResults, loading, isNewLookAndFeel, createGTMEvent } = useResults();
	const collapseSize = useFeature<number>(FEATURE_FLAGS.FACET_GROUP_COLLAPSE_SIZE) || 0;

	let facetGroups = (loading ? previousResults : results)?.facetGroups ?? [];
	const selectedFacetGroups = (loading ? previousResults : results)?.selectedFacetGroups ?? [];
	const expandedFacetGroups = savedExpandedFacetGroups ?? selectedFacetGroups.map(({ id }) => ({ id }));

	// If no facets are available, but there are selected facets, present the selected facets so the user can unselect them.
	if (facetGroups.length === 0 && selectedFacetGroups.length > 0) {
		facetGroups = [];
		selectedFacetGroups.forEach((selectedGroup) => {
			facetGroups.push({
				id: selectedGroup.id,
				name: selectedGroup.name,
				facets: selectedGroup.facets.map((selectedFacet) => ({
					facetId: selectedFacet.facetId,
					groupId: selectedGroup.id,
					value: selectedFacet.value,
					count: results?.count ?? 0
				})),
				range: selectedGroup.range,
				info: null,
				metadata: { hasMoreFacets: false },
				unitPrefix: null,
				unitSuffix: null
			});
		});
	}

	const isFacetListCollapsible = isNewLookAndFeel && collapseSize >= 1 && facetGroups.length > collapseSize;
	const isSelectedFacetBehindCollapse =
		isFacetListCollapsible && selectedFacetGroups.some((sfg) => facetGroups.some((fg, ix) => ix >= collapseSize && fg.id === sfg.id));
	const [isFacetListExpanded, setIsFacetListExpanded] = useState(!isNewLookAndFeel || isSelectedFacetBehindCollapse);
	useEffect(() => {
		if (isSelectedFacetBehindCollapse) {
			setIsFacetListExpanded(true);
		}
	}, [isSelectedFacetBehindCollapse]);

	if (facetGroups.length === 0) {
		return null;
	}

	const isFacetGroupExpanded = (group: FacetGroupType) => expandedFacetGroups.some(({ id }) => id === group.id);

	const toggleFacetGroupExpanded = (group: FacetGroupType) => {
		const isExpanded = isFacetGroupExpanded(group);
		const updatedFacetsGroups = isExpanded
			? expandedFacetGroups.filter((item) => item.id !== group.id)
			: [...expandedFacetGroups, { id: group.id }];
		const event = isExpanded ? TrackedEvent.COLLAPSE_FACET_GROUP : TrackedEvent.EXPAND_FACET_GROUP;
		void trackSearchEvent(createGTMEvent(event, { group }));
		setExpandedFacetGroups(updatedFacetsGroups);
	};
	const someFacetsGroupsExpanded = expandedFacetGroups.length > 0;
	const toggleAllFacetGroupsExpanded = (e: React.SyntheticEvent) => {
		e.preventDefault();
		const allFacetGroups = facetGroups.map(({ id }) => ({ id }));
		if (someFacetsGroupsExpanded) {
			setExpandedFacetGroups([]);
		} else if (allFacetGroups?.length) {
			setExpandedFacetGroups(allFacetGroups);
		}
	};

	const filteredGroups = isFacetListCollapsible && !isFacetListExpanded ? facetGroups.slice(0, collapseSize) : facetGroups;
	const toggleMoreFilters = () => {
		const newValue = !isFacetListExpanded;
		const action = newValue ? TrackedEvent.MORE_FILTERS : TrackedEvent.LESS_FILTERS;
		void trackSearchEvent(createGTMEvent(action, {}));
		setIsFacetListExpanded(newValue);
	};

	const wrapperClassName = !isNewLookAndFeel ? `ph2 ba-l ${facetListBackground}` : 'ph0 bg-white';

	return (
		<div className={`b--theme-tertiary pa0-ns ${wrapperClassName}`}>
			{!hideDesktopHeader && (
				<div
					className={`${
						isNewLookAndFeel ? 'bb b--theme-tertiary f4 flex-ns items-end justify-between pv2 ph1 lh-solid' : 'pa2 db-l'
					} dn fw3 ma0`}>
					Narrow Your Results
					<span
						onClick={toggleAllFacetGroupsExpanded}
						onKeyDown={handleKeys(['Enter', ' '], toggleAllFacetGroupsExpanded)}
						className={`${isNewLookAndFeel ? 'f6' : 'fw2'} pl3 theme-primary pointer underline-hover`}
						role="button"
						tabIndex={0}>
						{someFacetsGroupsExpanded ? 'COLLAPSE' : 'EXPAND'} ALL
					</span>
				</div>
			)}
			<ul
				className={`list ma0 pb2 pb0-l pl0 ${isNewLookAndFeel && 'pt0 pl2-l'} ${scrollable ? 'overflow-y-auto' : ''}`}
				style={listContainerStyle}>
				{includeSortBy && <SortByDropdown useResults={useResults} />}
				{filteredGroups.map((group) => {
					const selectedFacetGroup = selectedFacetGroups.find((selectedGroup) => group.id === selectedGroup.id);
					return (
						<FacetGroup
							group={group}
							useResults={useResults}
							useSearchFacetGroupResults={useSearchFacetGroupResults}
							selectedFacets={selectedFacetGroup?.facets}
							onClick={toggleFacetGroupExpanded}
							isExpanded={isFacetGroupExpanded(group)}
							key={group.id}
						/>
					);
				})}
				{isFacetListCollapsible ? (
					<li className="flex flex-row items-center justify-center w-100 pa2 mt3">
						<TextButton color="black" underline={true} onClick={toggleMoreFilters}>
							{isFacetListExpanded ? 'Less' : 'More'} Filters
						</TextButton>
					</li>
				) : null}
			</ul>
		</div>
	);
};
