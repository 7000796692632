import React, { FunctionComponent } from 'react';
import { FEATURE_FLAGS } from '../../../constants/general';
import { formatNumber, pluralize } from '../../../helpers/general-helper/general-helper';
import {
	useFacetGroupResults,
	UseFacetGroupResults,
	useSearchResults,
	UseSearchResultsPayload
} from '../../../hooks/apollo/search/search.hooks';
import { useFeature } from '../../../hooks/features/features.hooks';
import { StyledButton, TextButton } from '../../buttons';
import { Drawer } from '../../common-components/drawer/drawer.component';
import { PageLoading } from '../../common-components/loading/loading.component';
import { CloseIcon } from '../../svg/icons.component';
import { FacetFilterListV2 } from '../facet-filter-list-v2/facet-filter-list-v2.component';
import { FacetList } from '../facet-list/facet-list.component';
import { PlpNavVariations } from '../search-header-v2/search-header-v2.component';
import { facetDrawerFooter } from './facet-drawer.css';

export type FacetDrawerProps = {
	onFacetDrawerClose: () => void;
	useResults?: () => UseSearchResultsPayload;
	useSearchFacetGroupResults?: (facetGroupId: string) => UseFacetGroupResults;
	searchHeaderV2FlagStatus?: string;
};
export type FacetDrawerHeaderProps = UseSearchResultsPayload &
	FacetDrawerProps & { resultCount: string; searchHeaderV2FlagStatus?: string };

const FacetDrawerHeader: FunctionComponent<FacetDrawerHeaderProps> = ({
	resultCount,
	clearFacets,
	onFacetDrawerClose,
	isNewLookAndFeel,
	searchHeaderV2FlagStatus
}) => {
	return isNewLookAndFeel ? (
		<div className="pa3 bb b--theme-tertiary f4 fw7">
			<span className="f-inherit">Filter</span>
		</div>
	) : searchHeaderV2FlagStatus === PlpNavVariations.control ? (
		<>
			<div className="pl3 pv3 bb b--theme-tertiary f3 fw7">
				<span className="f-inherit">Filter</span>
				<span className="f-inherit i ml5 ml6-ns">{`${resultCount} Results`}</span>
			</div>
			<div className="flex justify-between pa3">
				<StyledButton buttonStyle="SECONDARY" onClick={clearFacets}>
					Reset Filters
				</StyledButton>
				<StyledButton buttonStyle="PRIMARY" onClick={onFacetDrawerClose}>
					View Results
				</StyledButton>
			</div>
		</>
	) : (
		<div>
			<div className="pl3 pv3 bb b--theme-tertiary f3 fw7">
				<span className="f-inherit">Filter</span>
				<span className="f-inherit i ml5 ml6-ns">{`${resultCount} Results`}</span>
			</div>
			<div className="flex pa3">
				<div className="flex flex-grow-1 w-100 pr1">
					<StyledButton buttonStyle="SECONDARY" onClick={clearFacets}>
						Reset Filters
					</StyledButton>
				</div>
				<div className="flex flex-grow-1 w-100 pl1">
					<StyledButton buttonStyle="PRIMARY" onClick={onFacetDrawerClose}>
						View Results
					</StyledButton>
				</div>
			</div>

			<div className={`bg-theme-grey-lighter overflow-x-scroll overflow-y-hidden nowrap pv3`}>
				<FacetFilterListV2 useResults={useSearchResults} />
			</div>
		</div>
	);
};

export const FacetDrawer: FunctionComponent<FacetDrawerProps> = ({
	onFacetDrawerClose,
	useResults = useSearchResults,
	useSearchFacetGroupResults = useFacetGroupResults,
	searchHeaderV2FlagStatus
}) => {
	const searchResults = useResults();
	const { results, previousResults, clearFacets, isNewLookAndFeel, loading } = searchResults;
	const listContainerStyle = isNewLookAndFeel
		? { paddingBottom: '2em', height: 'calc(100vh - 12rem' }
		: { height: 'calc(100vh - 210px)' };
	const resultCount = results?.count ? results.count : previousResults?.count || 0;
	const formattedCount = formatNumber(resultCount);
	const isSortInsideFacetDrawer = useFeature(FEATURE_FLAGS.SORT_INSIDE_FACET_DRAWER);

	const customCloseButton = isNewLookAndFeel ? (
		<div className="absolute right-0 top-0 pa3 mt1">
			<TextButton automationHook="clear-all-facets" underline={true} color="black" onClick={clearFacets}>
				Clear Filters
			</TextButton>
		</div>
	) : (
		<div role="button" aria-label="close" className="absolute right-0 top-0" tabIndex={0} data-testid="drawerCloseButton">
			<CloseIcon className="pa3 f3 theme-grey" />
		</div>
	);

	return (
		<Drawer
			ariaLabel="Filter Search Results"
			onClose={onFacetDrawerClose}
			direction="right"
			className="pa0 z-4"
			classContainerContent={isNewLookAndFeel ? 'overflow-x-hidden' : ''}
			customCloseButton={customCloseButton}>
			<FacetDrawerHeader
				{...searchResults}
				onFacetDrawerClose={onFacetDrawerClose}
				resultCount={formattedCount}
				searchHeaderV2FlagStatus={searchHeaderV2FlagStatus}
			/>
			<div className="relative">
				<FacetList
					listContainerStyle={listContainerStyle}
					useResults={useResults}
					useSearchFacetGroupResults={useSearchFacetGroupResults}
					hideDesktopHeader={true}
					scrollable={true}
					includeSortBy={isSortInsideFacetDrawer}
				/>
				{loading && (
					<div className="absolute top-0 w-100 h-100 bg-theme-white-o70 z-1">
						<PageLoading />
					</div>
				)}
			</div>
			{isNewLookAndFeel && (
				<div className={`sticky bottom-0 flex flex-column items-center w-100 ph2 pa3 ${facetDrawerFooter}`}>
					<StyledButton buttonStyle="PRIMARY" onClick={onFacetDrawerClose}>
						View {formattedCount} {pluralize('Result', Number(resultCount))}
					</StyledButton>
				</div>
			)}
		</Drawer>
	);
};
